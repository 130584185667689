import { getBooks } from '../Data/BooksData';

import { Link } from 'react-router-dom';

const Books = (props) => {

	const books = getBooks();

	return (
		<ul className="bookList">
			{books.map((book, i) => {
			const bookContent = (
				<>
					<img src={book.image} alt={book.title} />
					<div>
						{book.title}
					</div>
				</>
			);

			return (
				<li key={i}>
				{!book.comingSoon ? (
					<Link to={`/konyvek/${book.slug}`}>{bookContent}</Link>
				) : (
					bookContent
				)}
				</li>
			);
			})}
		</ul>
	);
}

export default Books;
