import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { marked } from 'marked';

import { getBookBySlug } from '../Data/BooksData';

import BackArrow from '../assets/images/backarrow.png';

const SpecificBook = (props) => {

	const { slug } = useParams();
	const [book, setBook] = useState(false);
	const [peek, setPeek] = useState(false);

	useEffect(() => {
		let book = getBookBySlug(slug);
		setBook(book);

	}, [slug]);

	const handleClick = (event) => {
		if (peek) {
		  // If the "peek" is active, deactivate it and prevent navigation
		  setPeek(false);
		  //event.preventDefault();
		}
	  };

	return (
		<div className="specificBook">
			<Link to="/konyvek" className="backLink" onClick={handleClick}>
				<img src={BackArrow} alt="Vissza a könyvekhez" className="backArrow" />
			</Link>
			

			{book && (
				<>
					<img src={book.image} alt={book.title} className="floatingCover" />
					<h1>{book.title}</h1>
					
					{/* {!peek && ( */}
						<div className="synopsis">
							{book.synopsis.split('\r\n').map((line, i) => {
								return (
									<p key={i}>{line === '' ? '\u00A0' : line}</p>
								);
							})}
						</div>
					{/* )} */}

					{peek && (
						<div className="peekInside" dangerouslySetInnerHTML={{ __html: marked(book.peek) }}></div>
					)}
					<a href="#" onClick={(e) => {e.preventDefault(); setPeek(!peek)} }>{peek ? 'Elrejt' : 'Kukkants bele'}</a>
				</>
			)}

			{!book && (
				<>
					404 - nincs könyv:-(
				</>
			)}
		</div>
	);
}

export default SpecificBook;
