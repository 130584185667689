function SeededRandom(seed = false) {
    const a = 16807; // multiplier
    const m = 2147483647; // 2**31 - 1
    let s;
	
	if ( seed === false ) {
		s = Math.floor(Math.random() * m);
	}

	this.setSeed = function(seed) {
		s = seed;
	}
	
    this.nextFloat = function() {
        s = (s * a) % m;
        return s / m;
    };

	this.nextInt = function() {
		return Math.floor(this.nextFloat() * 100);
	}

	this.nextIntRange = function(min, max) {
        return Math.floor(this.nextFloat() * (max - min + 1)) + min;
    }
}

export {SeededRandom};
