import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import sun from './assets/images/sun.png';
import henrik from './assets/images/henrik.png';
import csiga from './assets/images/csiga.png';
import madar from './assets/images/madar.png';
import pumpkin from './assets/images/pumpkin.png';
import mogyoro from './assets/images/mogyoro2.png';

import './App.css';
import Cloudmenu from './Components/Cloudmenu';

import Books from './Content/Books';
import SpecificBook from './Content/SpecificBook';
import Soon from './Content/Soon';

function App() {
	const [ responsiveMenuOpen, setResponsiveMenuOpen ] = useState(false);

	return (
		<Router>
			<div className="App">
				<header>
					<div className="navMenuToggle" onClick={() => setResponsiveMenuOpen(!responsiveMenuOpen)}>
						Menü
					</div>
					<div className="menuWrapper">
						<ul className={`nav ${!responsiveMenuOpen ? 'responsiveHidden' : ''}`}>
							<Cloudmenu to="/konyvek" cloudSize="10" customSeed="32" active="true">
								Könyvek
							</Cloudmenu>
							<Cloudmenu to="/szereplok" cloudSize="10" active="true">
								Szereplők
							</Cloudmenu>
							<Cloudmenu to="/velemenyek" cloudSize="10" customSeed="32" active="true">
								Vélemények
							</Cloudmenu>
							<Cloudmenu to="/vasarlas" cloudSize="9" customSeed="1" active="true">
								Vásárlás
							</Cloudmenu>
						</ul>
					</div>
					
					<img src={sun} className="sun" alt="napocska" />
				</header>

				{/* <div className="content-wrapper">
					<div className="content">
						<Routes>
							<Route path="/" element={<Books />} />

							<Route path="*" element={<div>404</div>} />
						</Routes>
					</div>
				</div> */}

				<img src={pumpkin} className="pumpkin" alt="Tök" />

				<div className="content-wrapper">
					<img src={csiga} className="csiga" alt="Csiga" />
					<img src={madar} className="madar" alt="Madár" />
					<div className="content">
						<Routes>
							{/* <Route path="/" element={<Books />} /> */}

							<Route path="/" element={<Navigate to="/konyvek" />} />

							<Route path="/konyvek" element={<Books />} />

							<Route path="/konyvek/:slug" element={<SpecificBook />} />

							<Route path="*" element={<Soon />} />
						</Routes>
					</div>
					<img src={henrik} className="henrik" alt="Hangya Henrik" />
				</div>
				
				<img src={mogyoro} className="mogyoro" alt="Mogyoró" />
			</div>
		</Router>
	);
}

export default App;
