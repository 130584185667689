import soon from '../assets/images/soon.webp';
const Soon = (props) => {

	return (
		<div id="comingsoon">
			<img src={soon} alt="Hamarosan" />
		</div>
	);
}

export default Soon;