import book1image1 from '../assets/images/books/hangya-henrik1-1.png';
import book1image2 from '../assets/images/books/hangya-henrik1-2.png';

const books = [
	{ 
		title: 'Hangya Henrik Kalandjai',
		cover: 'hangya-henrik1',
		slug: 'Hangya-Henrik-Kalandjai',
		comingSoon: false,
		synopsis: "A Hangya Henrik Kalandjai egy izgalmas, szórakoztató és tanulságos mesekönyv, amelyben a kis hangya, Henrik kalandjait követhetjük nyomon.\r\n\r\nA történetek során Henrik és barátai, a kis állatok különféle kalandokba keverednek, amelyek során sokat tanulhatnak a természetről, az állatokról és a barátságról.\r\nA könyv a kisgyermekek számára szórakoztató és tanulságos olvasmány, amelyben a kis olvasók megismerhetik a természet csodáit, és megtanulhatják, hogy a barátság milyen fontos az életben.",
		peek: `## A költözés

Hangya Henrik épp elköltözött a bolyból, ahol a szüleivel és testvéreivel élt. Hajtotta a kíváncsiság, szerette volna felfedezni a közeli erdőt, a szántóföldeket, de még jobban nagyon szeretett volna új barátokat szerezni. No meg szeretett volna egy kis nyugalmat is. Ügyes, bátor hangya volt ő, aki csepp termete ellenére bátran nekivágott az ismeretlennek.
Már elég hosszú ideje sétált az úton, amikor megpillantott egy takaros kis házat a sövény túloldalán, sok fával, sűrű bokorral. Az út szélén lágyan ringatózó bokor legmagasabb ágáról jól megnézte magának a házat, amit nagyon szépnek talált. Míg nézelődött, a kert felől vidám gyerek hangokat sodort feléje a szél, és ami még jobban lázba hozta, nagyon finom sütemény illata csiklandozta meg az orrát. Ez segített neki meghozni a döntést, hogy itt fog magának szállást találni.

![Hangya Henrik](${book1image1})

Körbejárta a kertet, és a bejárat mellett meg is lelte, amit keresett. Nagyot dobbant a szíve amikor meglátta a szép piros kalapos gombaházat, ami szinte hívogatta, hogy lépjen be az ajtaján. Szívdobogva bekopogott – és titokban nagyon reménykedett, hogy nem válaszol neki bentről senki. Szerencséje lett, mert nem jött egy kósza hang sem, ezért csendesen benyitott. Álmélkodva nézett körül, amikor megpillantotta a tágas nappalit, amiben akkor még csak egy kis kornyadozó virágot talált, majd felszaladt az emeletre is, ahol mindössze lámpa lógott a plafonról, de berendezve egyik helyiség sem volt. Összecsapta tenyerét és elhatározta, hogy kitakarít és berendezkedik.

![Hangya Henrik](${book1image2})

- Megtalálod a kertben Henrik házát?

A hősünk nemcsak bátor kishangya, hanem nagyon szorgalmas is. Néhány hét alatt csodaszéppé változtatta a gombaház belsejét.

A földszinten a falat kék, az emeleti hálószobáját piros virágokkal díszítette. Az alsó szintre tett egy szép kanapét, komódot és még egy kicsi akváriumot is aranyhalacskával. Az emeletet hálószobának rendezte be, a szoba közepére egy nagy ágyat tolt. Ha jól körülnézünk, akkor a polcon még látunk könyveket és virágokat. A tetőtérbe pedig azokat a tárgyakat helyezte el, amik már nem fértek el a lakásban. Van ott játékhangya, maci, és még egy kincsesláda is. Amikor már elégedett volt az eredménnyel, minden a helyére került, pihenésül felmászott egy a falhoz támasztott fűszálon, kimászott a tetőre, hanyatt feküdt és míg el nem
szunyókált a feje felett mozgó ágakat figyelte. Miután már elég kipihentnek érezte magát elérkezettnek látta az időt, hogy a házban lakó gyerekeket megismerje. Sokszor hallotta a nevetésüket, figyelte ahogy játszanak, és nagyon szeretett volna ő is közéjük tartozni, együtt játszani.ű
- Szerinted sikerül neki?`

	
	},
	{ 
		title: 'Hamarosan',
		cover: 'soon',
		comingSoon: true
	}
];

function getBooks() {
	let tempBooks = [...books];

	for (let i = 0; i < tempBooks.length; i++) {
		tempBooks[i].image = require(`../assets/images/covers/${tempBooks[i].cover}-small.png`);
	}

	return books;
}

function getBookBySlug(slug) {
	let b = books.find(book => book.slug === slug);

	b.image = require(`../assets/images/covers/${b.cover}-small.png`);
	return b;
}


export {getBooks, getBookBySlug}

