import { Link, useLocation } from 'react-router-dom';
import '../assets/css/Cloudmenu.css';

import mehecske from '../assets/images/mehecske.png';

const { useState, useEffect } = require("react");
const { SeededRandom } = require("../Classes/Tools");


const Cloudmenu = (props) => {
	const [cloudCoords, setCloudCoords] = useState([]);
	const [sizes, setSizes] = useState([10,10]);

	const { pathname } = useLocation();
	
	useEffect(() => {
		const rand = new SeededRandom();

		if ( typeof props.customSeed !== 'undefined' ) {
			console.log('custom seed: ', props.customSeed);
			rand.setSeed(props.customSeed);
		}
		else {
			console.log('cloudsize seed: ', props.cloudSize);
			rand.setSeed(props.cloudSize);
		}

		let coords = [];
		for (let i = 0; i < props.cloudSize; i++) {
			coords.push({
				x: rand.nextIntRange(0, 30) + (i * 20),
				y: (i === 0 ? 0 : rand.nextIntRange(0, 60) )
			});
		}

		let maxX = coords.reduce((max, p) => p.x > max ? p.x : max, coords[0].x) + 80 + 20;
		let maxY = coords.reduce((max, p) => p.y > max ? p.y : max, coords[0].y) + 80 + 20;

		console.log('maxX: ', maxX, 'maxY: ', maxY)
		setSizes([maxX, maxY]);

		setCloudCoords(coords);
	}, [props.cloudSize, props.customSeed]);


	return (
		<li className="nav-item cloudmenu" style={{	width: sizes[0], height: sizes[1] }}>
			<Link to={props.to} style={{ width: sizes[0], height: sizes[1] }}>
				<svg className="cloudmenu cloud" width={sizes[0]} height={sizes[1]} xmlns="http://www.w3.org/2000/svg">
					<defs>
						<filter id="blur">
							<feGaussianBlur stdDeviation="12" />
						</filter>
					</defs>
					
					{cloudCoords.map((e, i) => {
						return (
							<circle
								key={i}
								cx="50"
								cy="50"
								r="40"
								fill="white"
								filter="url(#blur)"
								style={{
									transform: `translate(${e.x}px, ${e.y}px)`
								}}
							/>
						)
					}, [])}
				</svg>
				
				<div className="cloudChildren">{props.children}</div>
				{pathname.startsWith(props.to) ? <img src={mehecske} className="mehecske" alt="Mehecske" /> : ''}
			</Link>
		</li>
	)
}

export default Cloudmenu;